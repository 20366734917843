import React from 'react';

export const Logo = (enabled = true) => {
  if (enabled) {
  return (
    <>
      <div>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 205.03 203.12">
          <path fill="#003F5F" d="M179.33,52.82c2.1,5.53,3.88,12.52,2.87,18.83-1.8,11.21-15.7,24.08-28.67,31.87-13.37,8.03-29.31,19.44-29.47,19.55-.11,.08-8.93,6.06-10.62,16.17-1.12,6.72,1.1,13.74,6.57,20.92,7.07,9.24,13.29,13.53,19.59,13.53h0c5.58,0,10.67-3.18,17.27-7.67,12.18-8.34,38.23-28.09,38.49-28.29,.64-.49,15.65-12.54,6.97-33.7-1.28-3.11-2.54-6.25-3.81-9.38-5.91-14.64-11.99-29.69-19.19-41.82Z"/>
          <path fill="#003F5F" d="M68.61,31.22c-5.2,0-10.42,2.76-16.71,6.36-12.8,7.38-40.29,25.06-40.57,25.24-.67,.44-16.57,11.31-9.54,33.02,1.06,3.27,2.1,6.56,3.14,9.84,4.76,15.01,9.63,30.41,15.86,43.01-1.66-5.68-2.9-12.79-1.41-19,2.64-11.04,17.5-22.8,31.04-29.57,13.95-6.95,30.69-17.11,30.86-17.21,.12-.07,9.38-5.36,11.84-15.32,1.63-6.6-.04-13.79-4.95-21.35-6.66-10.26-12.88-15.03-19.57-15.03Z"/>
          <path fill="#E3701E" d="M60.28,110.64c-5.5,0-11.26,2.15-17.12,6.4-20.76,15.08-14.59,24.62-6.78,36.7,8,12.39,27.09,38.91,27.28,39.19,.07,.09,7.71,10.19,21.49,10.19,3.87,0,7.91-.8,12.01-2.36,3.14-1.2,6.31-2.39,9.47-3.57,14.82-5.56,30.03-11.25,42.35-18.15-4.28,1.5-9.43,2.8-14.44,2.8-1.58,0-3.09-.13-4.49-.39-11.15-2.06-23.66-16.3-31.11-29.47-7.69-13.57-18.67-29.77-18.78-29.94-.09-.13-7.35-11.4-19.86-11.4Z"/>
          <path fill="#E3701E" d="M68.43,20.43c1.76,0,3.43,.16,4.97,.48,11.13,2.28,23.4,16.71,30.63,29.98,7.44,13.73,18.16,30.11,18.27,30.27,.08,.14,7.27,11.74,20,11.74,5.39,0,11.07-2.06,16.89-6.14,21.02-14.68,15-24.34,7.4-36.56-7.8-12.52-26.44-39.38-26.62-39.66-.07-.1-7.7-10.54-21.8-10.54-3.71,0-7.6,.72-11.54,2.15-3.27,1.18-6.57,2.36-9.86,3.53-14.8,5.27-30,10.67-42.34,17.32,4.17-1.39,9.15-2.57,14.02-2.57Z"/>
        </svg>
      </div>
    </>
  );
} else {
  return null
}
};

export const SubLogo = (enabled = true) => {
  if (enabled) {
  return (
    <>
      <div>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 28">
        <path fill="#1B91C7" d="M17.69,6a8.66,8.66,0,0,1,1.85.67A46.71,46.71,0,0,1,25,9.09a29.24,29.24,0,0,0-.76-7.2S20.57-.24,12.5-.24h0C4.92-.24,1.23,1.64.81,1.86A109.12,109.12,0,0,1,17.69,6Z" transform="translate(0 0.24)"/>
        <path fill="#1B91C7" d="M10.39,8.22C8.54,7.72,6.9,7.29,5.51,7h0C3.74,6.67,1.92,6.4.1,6.21a25.2,25.2,0,0,0,0,4.25c1,.18,2.87.54,5.07,1h0A78.22,78.22,0,0,1,19,15.88a12.64,12.64,0,0,1-6.41,6.26h0a12.56,12.56,0,0,1-6.67-7c-1-.18-2.92-.48-5.11-.7A20.91,20.91,0,0,0,12.5,27.76h0a20.81,20.81,0,0,0,12-14.33A91,91,0,0,0,10.39,8.22Z" transform="translate(0 0.24)"/>
        </svg>
      </div>
    </>
  );
} else {
  return null
}
};
