// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_PLAN_REQUEST,
  listPlanSuccess,
  listPlanFailure
} from '../../actions/Plan/Plan'

// Function Handle List Plan
function* handleListPlan(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/plan/${parameter.version}`

    // List Plan Request
    const response = yield call(axios.get_request, endpoint)
    // List Plan Success
    yield put(listPlanSuccess(response.data))
  } catch (error) {
    // List Plan Error
    yield put(listPlanFailure(error))
  }
}

// Export
export const PlanSaga = [
  takeLatest(LIST_PLAN_REQUEST, handleListPlan),
]