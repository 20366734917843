// List Authority Request
export const LIST_AUTHORITY_REQUEST = 'LIST_AUTHORITY_REQUEST'

export function listAuthorityRequest(request) {
  return { type: LIST_AUTHORITY_REQUEST, payload: request }
}

// List Authority Success
export const LIST_AUTHORITY_SUCCESS = 'LIST_AUTHORITY_SUCCESS'

export function listAuthoritySuccess(response) {
  return { type: LIST_AUTHORITY_SUCCESS, payload: response }
}

// List Authority Failure
export const LIST_AUTHORITY_FAILURE = 'LIST_AUTHORITY_FAILURE'

export function listAuthorityFailure(error) {
  return { type: LIST_AUTHORITY_FAILURE, payload: error }
}

// Retrieve Authority Request
export const RETRIEVE_AUTHORITY_REQUEST = 'RETRIEVE_AUTHORITY_REQUEST'

export function retrieveAuthorityRequest(request) {
  return { type: RETRIEVE_AUTHORITY_REQUEST, payload: request }
}

// Retrieve Authority Success
export const RETRIEVE_AUTHORITY_SUCCESS = 'RETRIEVE_AUTHORITY_SUCCESS'

export function retrieveAuthoritySuccess(response) {
  return { type: RETRIEVE_AUTHORITY_SUCCESS, payload: response }
}

// Retrieve Authority Failure
export const RETRIEVE_AUTHORITY_FAILURE = 'RETRIEVE_AUTHORITY_FAILURE'

export function retrieveAuthorityFailure(error) {
  return { type: RETRIEVE_AUTHORITY_FAILURE, payload: error }
}