import React from 'react';
import { RadioButtons } from "../Form/Input";

const columns = [
  {header: "Plan"},
  {header: "Cost"},
  {header: "Monthly Quota"},
  {header: "Excess Charge"}
]

const SimpleTable = ({enabled = true}) => {
 if (enabled){
  return (
    <div className="border border-gray-300 rounded-lg shadow-sm">
      <div className="header bg-blue-50 border-b border-blue-100 rounded-t-lg h-14 flex flex-row items-center">
        {columns.map((item) => (
          <div className="text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider w-full px-4">
            {item.header}
          </div>
        ))}
      </div>

      <div className="row h-14 flex flex-row items-center border-b border-blue-100">
          <div className="text-left w-full px-4"><RadioButtons id="free" name="plan" label="Free" type="radio" /></div>
          <div className="text-left w-full px-4">--</div>
          <div className="text-left w-full px-4">100</div>
          <div className="text-left w-full px-4">--</div>
      </div>
      <div className="row h-14 flex flex-row items-center border-b border-blue-100">
          <div className="text-left w-full px-4"><RadioButtons id="entry" name="plan" label="Entry" type="radio" /></div>
          <div className="text-left w-full px-4">$89 / Month</div>
          <div className="text-left w-full px-4">1,000</div>
          <div className="text-left w-full px-4">$0.12</div>
      </div>
      <div className="row h-14 flex flex-row items-center border-b border-blue-100">
          <div className="text-left w-full px-4"><RadioButtons id="advanced" name="plan" label="Advanced" type="radio" /></div>
          <div className="text-left w-full px-4">$349 / Month</div>
          <div className="text-left w-full px-4">5,000</div>
          <div className="text-left w-full px-4">$0.10</div>
      </div>
      <div className="row h-14 flex flex-row items-center border-b border-blue-100">
          <div className="text-left w-full px-4"><RadioButtons id="business" name="plan" label="Business" type="radio" /></div>
          <div className="text-left w-full px-4">$879 / Month</div>
          <div className="text-left w-full px-4">15,000</div>
          <div className="text-left w-full px-4">$0.08</div>
      </div>

    </div>
  );
        } else {
          return null
        }
};

export default SimpleTable;
