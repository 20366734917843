import React from 'react';

const ProgressBar = ({ enabled = true, completed}) => {
  if (enabled) {
  return (
    <div className="flex flex-row relative w-[97%] mx-auto">
      <div className="bg-gray-300 h-1.5 rounded-xl absolute w-full top-2"></div>
      <div className="bg-green-500 h-1.5 rounded-l-xl absolute top-2" style={{width: completed}}></div>
    </div>
  );
} else {
  return null
}
};

export default ProgressBar;
