import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import { Input } from "../../components/Form/Input";

const SignIn = (enabled = true) => {
  if (enabled) {
  return (
    <div className="relative h-full">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10 pt-2 md:pt-8 px-2">
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-3 text-2xl">Sign In</div>
            <div>
              <Input label="Email Address" type="text" name="email" id="email" />
            </div>
            <div>
              <Input label="Password" type="password" name="password" id="password" />
            </div>
            <div>
              <Button type="primary" size="sm" href="/#/">Sign In</Button>
            </div>
            <div className="flex flex-col mb-3">
              <a href="/#/">Forgot Password</a>
              <a href="/#/registration">Create Account</a>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
} else {
  return null
}
};

export default SignIn;
