// Additional Dependencies
import {
  LIST_RESOURCE_REQUEST,
  LIST_RESOURCE_SUCCESS,
  LIST_RESOURCE_FAILURE,
  RETRIEVE_RESOURCE_REQUEST,
  RETRIEVE_RESOURCE_SUCCESS,
  RETRIEVE_RESOURCE_FAILURE
} from '../../actions/Resource/Resource'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function ResourceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Resource Request
    case LIST_RESOURCE_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Resource Success
    case LIST_RESOURCE_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Resource Failure
    case LIST_RESOURCE_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Resource Request
    case RETRIEVE_RESOURCE_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Resource Success
    case RETRIEVE_RESOURCE_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Resource Failure
    case RETRIEVE_RESOURCE_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Resource"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default ResourceReducer