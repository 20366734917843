// Additional Dependencies
import {
  LIST_PLATFORM_REQUEST,
  LIST_PLATFORM_SUCCESS,
  LIST_PLATFORM_FAILURE,
  RETRIEVE_PLATFORM_REQUEST,
  RETRIEVE_PLATFORM_SUCCESS,
  RETRIEVE_PLATFORM_FAILURE
} from '../../actions/Platform/Platform'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function PlatformReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Platform Request
    case LIST_PLATFORM_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Platform Success
    case LIST_PLATFORM_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Platform Failure
    case LIST_PLATFORM_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Platform Request
    case RETRIEVE_PLATFORM_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Platform Success
    case RETRIEVE_PLATFORM_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Platform Failure
    case RETRIEVE_PLATFORM_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Platform"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default PlatformReducer