// List Product Request
export const LIST_PRODUCT_REQUEST = 'LIST_PRODUCT_REQUEST'

export function listProductRequest(request) {
  return { type: LIST_PRODUCT_REQUEST, payload: request }
}

// List Product Success
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS'

export function listProductSuccess(response) {
  return { type: LIST_PRODUCT_SUCCESS, payload: response }
}

// List Product Failure
export const LIST_PRODUCT_FAILURE = 'LIST_PRODUCT_FAILURE'

export function listProductFailure(error) {
  return { type: LIST_PRODUCT_FAILURE, payload: error }
}

// Retrieve Product Request
export const RETRIEVE_PRODUCT_REQUEST = 'RETRIEVE_PRODUCT_REQUEST'

export function retrieveProductRequest(request) {
  return { type: RETRIEVE_PRODUCT_REQUEST, payload: request }
}

// Retrieve Product Success
export const RETRIEVE_PRODUCT_SUCCESS = 'RETRIEVE_PRODUCT_SUCCESS'

export function retrieveProductSuccess(response) {
  return { type: RETRIEVE_PRODUCT_SUCCESS, payload: response }
}

// Retrieve Product Failure
export const RETRIEVE_PRODUCT_FAILURE = 'RETRIEVE_PRODUCT_FAILURE'

export function retrieveProductFailure(error) {
  return { type: RETRIEVE_PRODUCT_FAILURE, payload: error }
}