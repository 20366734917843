import React from 'react';

const Avatar = ({ enabled = true, img, size, color, editable}) => {
  if (enabled) {
  return (
    <div className="flex relative">
      {img.length ?
        (
          <img
            style={{height: size, width: size}}
            className={`inline-block rounded-full ring-2 ring-white`}
            src={img}
            alt=""
          />
      ) : <div style={{height: size, width: size}}><i style={{fontSize: size}} class={`fa-solid fa-circle-user text-${color}`}></i></div>
      }
    </div>
  );
} else {
  return null
}
};

export default Avatar;
