// Package Dependencies
import { all } from 'redux-saga/effects'
import { APISaga } from "../sagas/API/List"
import { AuthoritySaga } from "../sagas/Authority/List"
import { ConfigSaga } from "../sagas/Config/List"
import { PlanSaga } from "../sagas/Plan/List"
import { PlatformSaga } from "../sagas/Platform/List"
import { ProductSaga } from "../sagas/Product/List"
import { ResourceSaga } from "../sagas/Resource/List"
import { APISagaRetrieve } from "../sagas/API/Retrieve"
import { AuthoritySagaRetrieve } from "../sagas/Authority/Retrieve"
import { ConfigSagaRetrieve } from "../sagas/Config/Retrieve"
import { PlanSagaRetrieve } from "../sagas/Plan/Retrieve"
import { PlatformSagaRetrieve } from "../sagas/Platform/Retrieve"
import { ProductSagaRetrieve } from "../sagas/Product/Retrieve"
import { ResourceSagaRetrieve } from "../sagas/Resource/Retrieve"
// Export
export default function* RootSaga() {
  yield all([
    ...APISaga,
    ...AuthoritySaga,
    ...ConfigSaga,
    ...PlanSaga,
    ...PlatformSaga,
    ...ProductSaga,
    ...ResourceSaga,
    ...APISagaRetrieve,
    ...AuthoritySagaRetrieve,
    ...ConfigSagaRetrieve,
    ...PlanSagaRetrieve,
    ...PlatformSagaRetrieve,
    ...ProductSagaRetrieve,
    ...ResourceSagaRetrieve,
  ])
}
