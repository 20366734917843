import React from 'react';

const BreakPoints = ({enabled = true, display}) => {
  if (enabled) {
  return (
    <div>
    {display === "true" ? (
      <div className="h-9 w-9 bg-gray-900 text-white rounded-full flex items-center justify-center relative mr-4">
        <div className="block md:hidden">SM</div>
        <div className="hidden md:block lg:hidden">MD</div>
        <div className="hidden lg:block xl:hidden">LG</div>
        <div className="hidden xl:block 2xl:hidden">XL</div>
      </div>
      ) : null
    }
  </div>)
} else {
  return null
}
}

export default BreakPoints;
