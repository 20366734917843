// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_AUTHORITY_REQUEST,
  retrieveAuthoritySuccess,
  retrieveAuthorityFailure
} from '../../actions/Authority/Authority'

// Function Handle Retrieve Authority
function* handleRetrieveAuthority(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/authority/${parameter.version}/${data.uuid}`

    // Retrieve Authority Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Authority Success
    yield put(retrieveAuthoritySuccess(response.data))
  } catch (error) {
    // Retrieve Authority Error
    yield put(retrieveAuthorityFailure(error))
  }
}

// Export
export const AuthoritySagaRetrieve = [
  takeLatest(RETRIEVE_AUTHORITY_REQUEST, handleRetrieveAuthority),
]