import React from 'react';
import Button from "@alliancesafetycouncil/asc-button";
import MainCard from "@alliancesafetycouncil/asc-card";

const Products = ({ enabled, data }) => {
  if (enabled) {
    return (
      <MainCard type="main">
        <div className="grid grid-cols-3 gap-3">
            {data.map(product => {
              return (
                <div className="border border-blue-200 bg-blue-50 hover:bg-blue-100 rounded-lg p-3 flex flex-col">
                  <div className="flex flex-row items-center">
                    <div><i class={`${product.config.style.icon} text-3xl text-blue-500 mr-3`}></i></div>
                    <div className="text-xl font-medium">{product.name}</div>
                  </div>
                  <div className="text-gray-700 my-3">
                    {product.description}
                  </div>
                  <div className="flex flex-row">
                    {/*
                    <div className="mr-3">
                      <Button type="secondary" size="sm" href="/#/subscribe">Subscribe</Button>
                    </div>
                    */}
                    <div>
                      <Button type="tertiary" size="sm" href={`/#/details/${product.api.uuid}`}>View APIs</Button>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </MainCard>
    );
  } else {
    return null
  }
};

export default Products;
