import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import Products from "../../components/Products/Products";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import '@stoplight/elements/styles.min.css';
import { getFeature } from "../../lib/util";
import { useSelector } from 'react-redux'

const BrowsAPIs = (enabled = true) => {
  if (enabled) {
    const products = useSelector(state => state.productRedcuer.data)
    return (
      <div className="relative">
        <div className="fixed w-full z-20">
          <TopNav />
        </div>
        <div className="spacer-for-nav h-17"></div>
        <div className="p-2 md:p-4 h-full">
          <div className="mb-4">
            <PageTitle>
              <i className="fa-light fa-code text-orange-500 mr-2"></i>
              API Products
            </PageTitle>
          </div>
          <Products enabled={getFeature("Products")} data={products} />
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default BrowsAPIs;
