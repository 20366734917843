// List API Request
export const LIST_API_REQUEST = 'LIST_API_REQUEST'

export function listAPIRequest(request) {
  return { type: LIST_API_REQUEST, payload: request }
}

// List API Success
export const LIST_API_SUCCESS = 'LIST_API_SUCCESS'

export function listAPISuccess(response) {
  return { type: LIST_API_SUCCESS, payload: response }
}

// List API Failure
export const LIST_API_FAILURE = 'LIST_API_FAILURE'

export function listAPIFailure(error) {
  return { type: LIST_API_FAILURE, payload: error }
}

// Retrieve API Request
export const RETRIEVE_API_REQUEST = 'RETRIEVE_API_REQUEST'

export function retrieveAPIRequest(request) {
  return { type: RETRIEVE_API_REQUEST, payload: request }
}

// Retrieve API Success
export const RETRIEVE_API_SUCCESS = 'RETRIEVE_API_SUCCESS'

export function retrieveAPISuccess(response) {
  return { type: RETRIEVE_API_SUCCESS, payload: response }
}

// Retrieve API Failure
export const RETRIEVE_API_FAILURE = 'RETRIEVE_API_FAILURE'

export function retrieveAPIFailure(error) {
  return { type: RETRIEVE_API_FAILURE, payload: error }
}