// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_PLATFORM_REQUEST,
  listPlatformSuccess,
  listPlatformFailure
} from '../../actions/Platform/Platform'

// Function Handle List Platform
function* handleListPlatform(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/platform/${parameter.version}`

    // List Platform Request
    const response = yield call(axios.get_request, endpoint)
    // List Platform Success
    yield put(listPlatformSuccess(response.data))
  } catch (error) {
    // List Platform Error
    yield put(listPlatformFailure(error))
  }
}

// Export
export const PlatformSaga = [
  takeLatest(LIST_PLATFORM_REQUEST, handleListPlatform),
]