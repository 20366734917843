// Additional Dependencies
import {
  LIST_PLAN_REQUEST,
  LIST_PLAN_SUCCESS,
  LIST_PLAN_FAILURE,
  RETRIEVE_PLAN_REQUEST,
  RETRIEVE_PLAN_SUCCESS,
  RETRIEVE_PLAN_FAILURE
} from '../../actions/Plan/Plan'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function PlanReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Plan Request
    case LIST_PLAN_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Plan Success
    case LIST_PLAN_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Plan Failure
    case LIST_PLAN_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Plan Request
    case RETRIEVE_PLAN_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Plan Success
    case RETRIEVE_PLAN_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Plan Failure
    case RETRIEVE_PLAN_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Plan"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default PlanReducer