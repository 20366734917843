// Additional Dependencies
import {
  LIST_API_REQUEST,
  LIST_API_SUCCESS,
  LIST_API_FAILURE,
  RETRIEVE_API_REQUEST,
  RETRIEVE_API_SUCCESS,
  RETRIEVE_API_FAILURE
} from '../../actions/API/API'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function APIReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List API Request
    case LIST_API_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List API Success
    case LIST_API_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List API Failure
    case LIST_API_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve API Request
    case RETRIEVE_API_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve API Success
    case RETRIEVE_API_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        api: action.payload.data
      }
    // Retrieve API Failure
    case RETRIEVE_API_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve API"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default APIReducer