// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_CONFIG_REQUEST,
  listConfigSuccess,
  listConfigFailure
} from '../../actions/Config/Config'

// Function Handle List Config
function* handleListConfig(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/config/${parameter.version}`

    // List Config Request
    const response = yield call(axios.get_request, endpoint)
    // List Config Success
    yield put(listConfigSuccess(response.data))
  } catch (error) {
    // List Config Error
    yield put(listConfigFailure(error))
  }
}

// Export
export const ConfigSaga = [
  takeLatest(LIST_CONFIG_REQUEST, handleListConfig),
]