import React from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import { Logo } from "../../components/Logo/Logo";
import { Input } from "../../components/Form/Input";
import Avatar from "../../components/Avatar/Avatar";
import { Link } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { getFeature } from "../../lib/util";

const data = { progress: "33%" }

const FormRegistration = (enabled = true) => {
  if (enabled) {
    return (
      <div className="p-2 md:p-3">
        <div className="flex items-center justify-center mb-6 mt-2">
          <div className="w-[32px] mr-2 h-[30px]">
            <Logo />
          </div>
          <div className="mr-6 text-2xl font-light">Development</div>
        </div>
        <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto">
          <div>
            <ProgressBar completed={data.progress} />
          </div>
          <MainCard type="main">
            <div className="grid grid-cols-1 gap-6">
              <div className="mt-6 text-2xl">Create Account</div>
              <div className="flex flex-row items-center">
                <div className="relative">
                  <Avatar img="" size={100} color="gray-300" enabled={getFeature("Avatar")} />
                  <div className="absolute right-0 bottom-0">
                    <Link to="#">
                      <div className="flex h-8 w-8 bg-gray-500 hover:bg-gray-600 rounded-full justify-center items-center ring-2 ring-white">
                        <i className="fa-light fa-camera text-white text-xl"></i>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="ml-3 flex flex-col">
                  <div className="text-lg">Upload you photo</div>
                </div>
              </div>
              <div>
                <Input label="First Name" type="text" />
              </div>
              <div>
                <Input label="Last Name Name" type="text" />
              </div>
              <div>
                <Input label="Email Address" type="text" subLabel="We will email you a link to validate your email account." />
              </div>
              <div>
                <Input label="Password" type="password" />
              </div>
              <div>
                <Input label="Confirm Password" type="password" />
              </div>
              <div className="flex flex-row">
                <div>
                  <Button type="primary" size="sm" href="/#/validation-listening">Continue</Button>
                </div>
                <div className="ml-3">
                  <Button type="tertiary" size="sm" href="/#/sign-in">Back</Button>
                </div>
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default FormRegistration;
