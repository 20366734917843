import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import Categories from "../../components/Categories/Categories";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import Illustration from "../../assets/img/SafetyIllustration.png";
import { getFeature } from "../../lib/util";
import { useSelector } from 'react-redux'

const Home = (enabled = true) => {
  if (enabled) {
    const products = useSelector(state => state.productRedcuer.data)
    return (
      <div className="relative h-full">
        <div className="fixed w-full z-20">
          <TopNav />
        </div>
        <div className="spacer-for-nav h-17"></div>
        <div className="grid grid-col-1 gap-4 p-2 sm:p-4">

          {/* Card */}
          {
            getFeature("Banner") && (

              <div className="flex flex-1 p-4 rounded-xl bg-blue-300 text-gray-900 shadow-md h-full flex-col">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="p-2 md:p-8">
                    <div className="text-3xl md:text-[4vw] leading-none md:leading-[4.3vw] text-white font-light">
                      Working with Alliance Development APIs
                    </div>
                    <div className="text-xl md:text-[1.75vw] leading-tight md:leading-[2vw] text-white font-light mt-6">
                      Alliance developers will find all future APIs in this portal and documentation on how to use them. In the future, any developer can register apps and use our APIs.
                    </div>
                    <div className="mt-8 flex flex-row">
                      {
                        getFeature("LearnMore") && (
                          <div className="mr-3">
                            <Button type="secondary" size="md" href="/#/"><span className="py-3 px-3">Learn More</span></Button>
                          </div>
                        )
                      }
                      {
                        getFeature("SignUp") && (
                          <div>
                            <Button type="primary" size="md" href="/#/registration"><span className="py-3 px-3">Sign Up</span></Button>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="px-8 pt-6 hidden md:block">
                    <img src={Illustration} alt="Safety Illustration" />
                  </div>
                </div>
              </div>
            )
          }

          <div className="grid grid-cols-1 gap-4">
            {
              getFeature("API Products") && (
                <MainCard type="main">
                  <div className="p-2 md:p-6 flex flex-col">
                    <div className="text-2xl">
                      API Products
                    </div>
                    <div className="text-gray-600 text-lg mb-8">
                      Find Alliance APIs that best support your apps.
                    </div>
                    <div className="grid grid-cols-5 gap-3">
                      {
                        products.map(product => {
                          return (
                            <Categories href={`/#/details/${product.api.uuid}`} icon={product.config.style.icon} title={product.name} key={product.name} />
                          )
                        })
                      }
                      <Categories href={"/#/browse"} icon={"fa-light fa-ellipsis"} title={"View All"} key={"View All"} />

                    </div>

                  </div>
                </MainCard>
              )
            }
            {
              getFeature("My Apps") && (
                <MainCard type="main">
                  <div className="p-2 md:p-6 flex flex-col">
                    <div className="text-2xl">
                      My Apps
                    </div>
                    <div className="text-gray-600 text-lg mb-8">
                      View all your apps and APIs you subscribed.
                    </div>
                    <div>
                      <Button href="/#/my-apps" size="sm" type="secondary">View My Apps</Button>
                    </div>
                  </div>
                </MainCard>
              )
            }

          </div>

        </div>
      </div>
    );
  } else {
    return null
  }
};

export default Home;
