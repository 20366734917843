// List Config Request
export const LIST_CONFIG_REQUEST = 'LIST_CONFIG_REQUEST'

export function listConfigRequest(request) {
  return { type: LIST_CONFIG_REQUEST, payload: request }
}

// List Config Success
export const LIST_CONFIG_SUCCESS = 'LIST_CONFIG_SUCCESS'

export function listConfigSuccess(response) {
  return { type: LIST_CONFIG_SUCCESS, payload: response }
}

// List Config Failure
export const LIST_CONFIG_FAILURE = 'LIST_CONFIG_FAILURE'

export function listConfigFailure(error) {
  return { type: LIST_CONFIG_FAILURE, payload: error }
}

// Retrieve Config Request
export const RETRIEVE_CONFIG_REQUEST = 'RETRIEVE_CONFIG_REQUEST'

export function retrieveConfigRequest(request) {
  return { type: RETRIEVE_CONFIG_REQUEST, payload: request }
}

// Retrieve Config Success
export const RETRIEVE_CONFIG_SUCCESS = 'RETRIEVE_CONFIG_SUCCESS'

export function retrieveConfigSuccess(response) {
  return { type: RETRIEVE_CONFIG_SUCCESS, payload: response }
}

// Retrieve Config Failure
export const RETRIEVE_CONFIG_FAILURE = 'RETRIEVE_CONFIG_FAILURE'

export function retrieveConfigFailure(error) {
  return { type: RETRIEVE_CONFIG_FAILURE, payload: error }
}