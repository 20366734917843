// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_CONFIG_REQUEST,
  retrieveConfigSuccess,
  retrieveConfigFailure
} from '../../actions/Config/Config'

// Function Handle Retrieve Config
function* handleRetrieveConfig(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/config/${parameter.version}/${data.uuid}`

    // Retrieve Config Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Config Success
    yield put(retrieveConfigSuccess(response.data))
  } catch (error) {
    // Retrieve Config Error
    yield put(retrieveConfigFailure(error))
  }
}

// Export
export const ConfigSagaRetrieve = [
  takeLatest(RETRIEVE_CONFIG_REQUEST, handleRetrieveConfig),
]