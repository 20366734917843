import React, { useEffect, useState } from "react";
import TopNav from "../../components/Navigation/TopNav";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import { StoplightProject } from '@stoplight/elements-dev-portal';
import '@stoplight/elements/styles.min.css';
import { useDispatch, useSelector } from "react-redux"
import { listAPIRequest } from "../../actions/API/API"
import { listResourceRequest } from "../../actions/Resource/Resource";
import { useParams } from "react-router-dom";

const APIDetails = (enabled = true) => {
  if (enabled) {
    const [project, setProject] = useState(null)
    const dispatch = useDispatch()
    let { uuid } = useParams()

    const api = useSelector(state => state.apiReducer.data.find(api => api.uuid === uuid))
    const resource = useSelector(state => state.resourceReducer.data)

    useEffect(() => {
      if (api && resource) {
        const details = resource.find(r => r.name === api.name)
        if (details) {
          setProject(details)
        }
      }
      return () => {
        setProject(null)
      }
    }, [api, resource])

    useEffect(() => {
      dispatch(listAPIRequest())
      dispatch(listResourceRequest(uuid))
    }, [uuid])

    if (project) {
      return (
        <div className="relative">
          <div className="fixed w-full z-20">
            <TopNav />
          </div>
          <div className="spacer-for-nav h-17"></div>
          <div className="p-2 md:p-4 h-full">
            <div className="mb-4">
              <PageTitle>
                <i className="fa-light fa-book-open text-orange-500 mr-2"></i>
                Browse APIs
              </PageTitle>
            </div>
            <MainCard type="main">
              <div className="h-full">
                <div className="relative flex flex-col">
                  <div>
                    <StoplightProject projectId={project.data.StoplightProject.ProjectId} hideMocking={true} router="memory" />
                  </div>
                </div>
              </div>
            </MainCard>
          </div>
        </div>
      );
    }
  } else {
    return null
  }
};

export default APIDetails;
