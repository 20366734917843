// Additional Dependencies
import {
  LIST_AUTHORITY_REQUEST,
  LIST_AUTHORITY_SUCCESS,
  LIST_AUTHORITY_FAILURE,
  RETRIEVE_AUTHORITY_REQUEST,
  RETRIEVE_AUTHORITY_SUCCESS,
  RETRIEVE_AUTHORITY_FAILURE
} from '../../actions/Authority/Authority'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function AuthorityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Authority Request
    case LIST_AUTHORITY_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Authority Success
    case LIST_AUTHORITY_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Authority Failure
    case LIST_AUTHORITY_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Authority Request
    case RETRIEVE_AUTHORITY_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Authority Success
    case RETRIEVE_AUTHORITY_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Authority Failure
    case RETRIEVE_AUTHORITY_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Authority"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default AuthorityReducer