// Additional Dependencies
import {
  LIST_PRODUCT_REQUEST,
  LIST_PRODUCT_SUCCESS,
  LIST_PRODUCT_FAILURE,
  RETRIEVE_PRODUCT_REQUEST,
  RETRIEVE_PRODUCT_SUCCESS,
  RETRIEVE_PRODUCT_FAILURE
} from '../../actions/Product/Product'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function ProductReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Product Request
    case LIST_PRODUCT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Product Success
    case LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Product Failure
    case LIST_PRODUCT_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Product Request
    case RETRIEVE_PRODUCT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Product Success
    case RETRIEVE_PRODUCT_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Product Failure
    case RETRIEVE_PRODUCT_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Product"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default ProductReducer