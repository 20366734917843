// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_RESOURCE_REQUEST,
  listResourceSuccess,
  listResourceFailure
} from '../../actions/Resource/Resource'

// Function Handle List Resource
function* handleListResource(action) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/resource/${parameter.version}`

    if (action.payload.query) {
      endpoint += `?type_uuid=ba0fc0e8-f1d6-4d64-bcb0-f49416ad94bc&provider_uuid=b8c7de48-45fa-4c19-8da1-b961314feba7&api_uuid=${action.payload.query}`
    }

    // List Resource Request
    const response = yield call(axios.get_request, endpoint)
    // List Resource Success
    yield put(listResourceSuccess(response.data))
  } catch (error) {
    // List Resource Error
    yield put(listResourceFailure(error))
  }
}

// Export
export const ResourceSaga = [
  takeLatest(LIST_RESOURCE_REQUEST, handleListResource),
]