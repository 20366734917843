// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_PLAN_REQUEST,
  retrievePlanSuccess,
  retrievePlanFailure
} from '../../actions/Plan/Plan'

// Function Handle Retrieve Plan
function* handleRetrievePlan(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/plan/${parameter.version}/${data.uuid}`

    // Retrieve Plan Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Plan Success
    yield put(retrievePlanSuccess(response.data))
  } catch (error) {
    // Retrieve Plan Error
    yield put(retrievePlanFailure(error))
  }
}

// Export
export const PlanSagaRetrieve = [
  takeLatest(RETRIEVE_PLAN_REQUEST, handleRetrievePlan),
]