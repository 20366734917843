import { feature } from "../config/feature.json"

/**
 * Check if the component is enabled in the feature flag config
 * 
 * @param {string} component name of the component
 * @returns {boolean} component enabled flag
 */
export const getFeature = (component) => {
  return feature && feature.find(c => c.component === component).enabled
}