// Additional Dependencies
import {
  LIST_CONFIG_REQUEST,
  LIST_CONFIG_SUCCESS,
  LIST_CONFIG_FAILURE,
  RETRIEVE_CONFIG_REQUEST,
  RETRIEVE_CONFIG_SUCCESS,
  RETRIEVE_CONFIG_FAILURE
} from '../../actions/Config/Config'
// Define Initial State
const INITIAL_STATE = {
  busy: false,
  response: null,
  error: null,
  uuid: null,
  data: []
}

function ConfigReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // List Config Request
    case LIST_CONFIG_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // List Config Success
    case LIST_CONFIG_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.payload.data
      }
    // List Config Failure
    case LIST_CONFIG_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Complete Request"
      }
    // Retrieve Config Request
    case RETRIEVE_CONFIG_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Config Success
    case RETRIEVE_CONFIG_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        uuid: action.payload.data.uuid,
        data: action.payload.data.data
      }
    // Retrieve Config Failure
    case RETRIEVE_CONFIG_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Unable to Retrieve Config"
      }
    // Default State
    default:
      return state
  }
}

// Export
export default ConfigReducer