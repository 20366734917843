// List Plan Request
export const LIST_PLAN_REQUEST = 'LIST_PLAN_REQUEST'

export function listPlanRequest(request) {
  return { type: LIST_PLAN_REQUEST, payload: request }
}

// List Plan Success
export const LIST_PLAN_SUCCESS = 'LIST_PLAN_SUCCESS'

export function listPlanSuccess(response) {
  return { type: LIST_PLAN_SUCCESS, payload: response }
}

// List Plan Failure
export const LIST_PLAN_FAILURE = 'LIST_PLAN_FAILURE'

export function listPlanFailure(error) {
  return { type: LIST_PLAN_FAILURE, payload: error }
}

// Retrieve Plan Request
export const RETRIEVE_PLAN_REQUEST = 'RETRIEVE_PLAN_REQUEST'

export function retrievePlanRequest(request) {
  return { type: RETRIEVE_PLAN_REQUEST, payload: request }
}

// Retrieve Plan Success
export const RETRIEVE_PLAN_SUCCESS = 'RETRIEVE_PLAN_SUCCESS'

export function retrievePlanSuccess(response) {
  return { type: RETRIEVE_PLAN_SUCCESS, payload: response }
}

// Retrieve Plan Failure
export const RETRIEVE_PLAN_FAILURE = 'RETRIEVE_PLAN_FAILURE'

export function retrievePlanFailure(error) {
  return { type: RETRIEVE_PLAN_FAILURE, payload: error }
}