import React from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import Avatar from "../../components/Avatar/Avatar";
import { Link } from "react-router-dom";
import TopNav from "../../components/Navigation/TopNav";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import { Input } from "../../components/Form/Input";
import { getFeature } from "../../lib/util";

const user = {
  firstName: 'Tom',
  lastName: 'Cook',
  imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  email: 'tcook@email.com',
  BackgroundImg: 'https://cdn3.vectorstock.com/i/1000x1000/25/02/blue-crystal-pattern-background-vector-19752502.jpg'
}

const FormRegistration = (enabled = true) => {
  if (enabled) {
    return (
      <div>
        <div className="fixed w-full z-20">
          <TopNav />
        </div>
        <div className="spacer-for-nav h-17"></div>
        <div className="p-2 md:p-4 h-full w-full">
          <div className="mb-4">
            <PageTitle>
              <i className="fa-light fa-user-circle text-orange-500 mr-2"></i>
              My Profile
            </PageTitle>
          </div>
          <MainCard type="main">
            <div
              style={{
                backgroundImage: `url(${user.BackgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '95px',
                minHeight: '95px'
              }}
              className="rounded-t-lg relative"
            >
              <div className="relative sm:absolute left-8 top-3">
                <Avatar img={user.imageUrl} size={100} color="gray-300" enabled={getFeature("Avatar")} />
                <div className="absolute right-0 bottom-0">
                  <Link to="#">
                    <div className="flex h-8 w-8 bg-gray-500 hover:bg-gray-600 rounded-full justify-center items-center ring-2 ring-white">
                      <i className="fa-light fa-camera text-white text-xl"></i>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 border border-gray-300 border-t-0 rounded-b-lg gap-4 pt-12 px-8 pb-3">

              <div>
                <Input label="First Name" type="text" value={user.firstName} />
              </div>
              <div>
                <Input label="Last Name Name" type="text" value={user.lastName} />
              </div>
              <div>
                <Input label="Email Address" type="text" subLabel="We will email you a link to validate your email account." value={user.email} />
              </div>
              <div>
                <Input label="Password" type="password" value="aaaaaaaa" />
              </div>
              <div>
                <Input label="Confirm Password" type="password" value="aaaaaaaa" />
              </div>
              <div className="mb-1 flex flex-row">
                <div className="mr-3">
                  <Button type="save" size="sm" href="#">Save</Button>
                </div>
                <div>
                  <Button type="tertiary" size="sm" href="#">Cancel</Button>
                </div>

              </div>

            </div>
          </MainCard>
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default FormRegistration;
