import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import { AppToken } from "../../components/Form/Input";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import More from "../../components/Navigation/More";
import { getFeature } from "../../lib/util";

const apps = [
  { title: "My App", id: "rKii8GWcNfpNR8z9uxuCX8lAGV56wIek" },
  { title: "Another Cool App", id: "rKii8GWcNfpNR8z9uxuCX8lAGV56wIek" },
  { title: "Safety Software", id: "Test" },
];

const moreOptions = [
  { option: "Edit", icon: <i className="fa-light fa-pen-to-square"></i> },
  { option: "Delete", icon: <i className="fa-light fa-times"></i> },
];

const MyApps = (enabled = true) => {
  if (enabled) {
  return (
    <div className="relative">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 md:p-4">
        <div className="mb-4">
          <PageTitle>
            <i className="fa-light fa-grid-2-plus text-orange-500 mr-2"></i>
            My Apps
          </PageTitle>
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="col-span-2 flex justify-end">
              <Button type="primary" size="sm" href="/#/">
                Add Application
              </Button>
            </div>

            {apps.map((item) => (
              <div className="border border-blue-200 bg-blue-50 rounded-lg p-3">
                <div className="flex flex-row">
                  <div className="bg-blue-300 mr-3 h-14 w-14 rounded-lg flex justify-center items-center">
                    <i className="fa-regular fa-compass-drafting text-white text-3xl"></i>
                  </div>
                  <div className="lex flex-grow flex-col">
                    <div className="text-lg font-bold flex flex-row">
                      <div className="flex flex-grow">{item.title}</div>
                      <div>
                        <More items={moreOptions} />
                      </div>
                    </div>
                    <div className="mt-1">
                      <AppToken value={item.id} enabled={getFeature("AppToken")}/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </MainCard>
      </div>
    </div>
  );
} else {
  return null
}
};

export default MyApps;
