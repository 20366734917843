// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_RESOURCE_REQUEST,
  retrieveResourceSuccess,
  retrieveResourceFailure
} from '../../actions/Resource/Resource'

// Function Handle Retrieve Resource
function* handleRetrieveResource(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/resource/${parameter.version}/${data.uuid}`

    // Retrieve Resource Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Resource Success
    yield put(retrieveResourceSuccess(response.data))
  } catch (error) {
    // Retrieve Resource Error
    yield put(retrieveResourceFailure(error))
  }
}

// Export
export const ResourceSagaRetrieve = [
  takeLatest(RETRIEVE_RESOURCE_REQUEST, handleRetrieveResource),
]