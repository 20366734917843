import React from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { Logo } from "../Logo/Logo";
import cartItems from "../../data/CartItems";
import BreakPoints from "../BreakPoints/BreakPoints";
import Avatar from "../Avatar/Avatar";
import { getFeature } from "../../lib/util";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const user = {
  name: "Tom Cook",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  loggedIn: false,
};
const navigation = [
  {
    name: "Home",
    icon: <i className="fa-regular fa-home"></i>,
    href: "/",
    loggedIn: false,
  },
  {
    name: "My Apps",
    icon: <i className="fa-regular fa-grid-2-plus"></i>,
    href: "/my-apps",
    loggedIn: true,
  },
  {
    name: "API Products",
    icon: <i className="fa-regular fa-code"></i>,
    href: "/browse",
    loggedIn: false,
  },
  {
    name: "Documentation",
    icon: <i className="fa-regular fa-file"></i>,
    href: "/documentation",
    loggedIn: false,
  },
  {
    name: "API Sandbox",
    icon: <i className="fa-regular fa-umbrella-beach"></i>,
    href: "/sandbox",
    loggedIn: false,
  },
  {
    name: "Shopping Cart",
    icon: <i className="fa-regular fa-cart-shopping"></i>,
    href: "/cart",
    loggedIn: false,
  },
];
const userNavigation = [
  {
    name: "My Profile",
    href: "/profile",
    icon: <i className="fa-light fa-user"></i>,
    loggedIn: true,
  },
  {
    name: "Create Account",
    href: "/registration",
    icon: <i className="fa-light fa-plus-circle"></i>,
    loggedIn: false,
  },
  {
    name: "Support",
    href: "/support",
    icon: <i className="fa-light fa-question-circle"></i>,
    loggedIn: true,
  },
  {
    name: "Support",
    href: "/support",
    icon: <i className="fa-light fa-question-circle"></i>,
    loggedIn: false,
  },
  {
    name: "Sign In",
    href: "/sign-in",
    icon: <i className="fa-light fa-sign-in"></i>,
    loggedIn: false,
  },
  {
    name: "Sign out",
    href: "/sign-in",
    icon: <i className="fa-light fa-sign-out"></i>,
    loggedIn: true,
  },
];

export default function TopNav(enabled = true) {
  const location = useLocation()

  if (enabled) {
    return (
      <div>
        <Disclosure as="nav" className="bg-white shadow md:shadow-sm">
          {({ open }) => (
            <>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-17">
                  <div className="flex items-center">
                    <div className="w-[32px] mr-2 h-[30px]">
                      <Logo />
                    </div>
                    <div className="mr-6 text-2xl font-light">Development</div>
                    <div className="hidden lg:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => {
                          if (getFeature(item.name)) {
                            return (
                              <NavLink
                                key={item.name}
                                to={item.href}
                                className="text-gray-600 hover:bg-blue-50 px-3 py-5 flex flex-row items-center last:hidden"
                                activeClassName={(item.href === location.pathname && "!text-blue-500 border-b-2 border-blue-500")}
                              >
                                <div className="mr-2 text-lg">{item.icon}</div>
                                <div>{item.name}</div>
                              </NavLink>
                            )
                          } else {
                            return null
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <BreakPoints display="false" enbaled={getFeature("BreakPoints")} />

                      {/* Shopping Cart */}
                      {
                        getFeature("ShoppingCart") && (
                          cartItems.length > 0 ? (
                            <a href="/#/cart">
                              <div className="h-9 w-9 bg-blue-400 rounded-full flex items-center justify-center relative">
                                <div className="absolute -top-2px -right-2px bg-red-bright rounded-full h-[12px] w-[12px] border border-white"></div>
                                <i className="fa-regular fa-shopping-cart text-white"></i>
                              </div>
                            </a>
                          ) : (
                            <a href="/#/cart">
                              <div className="h-9 w-9 bg-gray-500 rounded-full flex items-center justify-center">
                                <i className="fa-regular fa-shopping-cart text-white text-lg"></i>
                              </div>
                            </a>
                          )
                        )
                      }

                      {/* Start Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs rounded-full flex items-center focus:outline-none focus:ring-none">
                            <span className="sr-only">Open user menu</span>
                            {user.loggedIn ? (
                              <Avatar
                                img={user.imageUrl}
                                size={35}
                                color="gray-500"
                                enabled={getFeature("Avatar")}
                              />
                            ) : (
                              <Avatar img="" size={35} color="gray-500" />
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => {
                              return (
                                <>
                                  {
                                    user.loggedIn === true && item.loggedIn === true ? (
                                      <Menu.Item key={item.name}>
                                        <NavLink
                                          to={item.href}
                                          className="block px-4 py-2 text-blue-500 last:border-t border-blue-100 flex flex-row bg-white hover:bg-blue-50"
                                          activeClassName="!bg-blue-100"
                                        >
                                          <div className="mr-2">{item.icon}</div>
                                          <div>{item.name}</div>
                                        </NavLink>
                                      </Menu.Item>
                                    ) : user.loggedIn === false &&
                                      item.loggedIn === false ? (
                                      <Menu.Item key={item.name}>
                                        <NavLink
                                          to={item.href}
                                          className="block px-4 py-2 text-blue-500 last:border-t border-blue-100 flex flex-row bg-white hover:bg-blue-50"
                                          activeClassName="!bg-blue-100"
                                        >
                                          <div className="mr-2">{item.icon}</div>
                                          <div>{item.name}</div>
                                        </NavLink>
                                      </Menu.Item>
                                    ) : null
                                  }
                                </>
                              )
                            })}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      {/* End Profile dropdown */}
                    </div>
                  </div>
                  <div className="-mr-2 flex lg:hidden items-center">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-bhite inline-flex items-center justify-center p-2 hover:text-blue-500">
                      <BreakPoints display="false" enbaled={getFeature("BreakPoints")} />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <i className="fa-light fa-times text-xl w-4"></i>
                      ) : (
                        <i className="fa-light fa-bars text-xl w-4"></i>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => {
                    if (getFeature(item.name)) {
                      return (
                        <NavLink
                          key={item.name}
                          as="a"
                          to={item.href}
                          className="bg-bwhite text-blue-500 block px-3 py-2 rounded-md text-base flex flex-row items-center items-center"
                          activeClassName="!bg-blue-100"
                        >
                          {item.name === "Shopping Cart" && cartItems.length > 0 ? (
                            <div className="mr-1 bg-red-bright rounded-full h-[12px] w-[12px] border border-white"></div>
                          ) : null}
                          <div className="mr-2 text-lg">{item.icon}</div>
                          <div>{item.name}</div>
                        </NavLink>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>

                {/* Profile */}
                <div className="pt-4 pb-3 border-t border-gray-300">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      {user.loggedIn ? (
                        <Avatar
                          img={user.imageUrl}
                          size={35}
                          color="gray-500"
                          enabled={getFeature("Avatar")}
                        />
                      ) : (
                        null
                      )}
                    </div>
                    {user.loggedIn ? (
                      <div className="ml-3">
                        <div className="leading-none text-blue-500">
                          {user.name}
                        </div>
                      </div>
                    ) : null
                    }
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (


                      user.loggedIn === true && item.loggedIn === true ?
                        (
                          <NavLink
                            key={item.name}
                            as="a"
                            to={item.href}
                            className="bg-white block px-3 py-2 rounded-md text-base text-blue-500"
                            activeClassName="!bg-blue-100"
                          >
                            {item.name}
                          </NavLink>
                        ) : user.loggedIn === false && item.loggedIn === false ?
                          (
                            <NavLink
                              key={item.name}
                              as="a"
                              to={item.href}
                              className="bg-white block px-3 py-2 rounded-md text-base text-blue-500"
                              activeClassName="!bg-blue-100"
                            >
                              {item.name}
                            </NavLink>
                          ) : null


                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  } else {
    return null
  }
}
