import React from "react";
import Avatar from "../Avatar/Avatar";
import NumberFormat from "react-number-format";
import { useState } from "react";
import { getFeature } from "../../lib/util";

export const Input = ({
  label,
  type,
  name,
  id,
  subLabel,
  placeholder,
  value,
  enabled = true
}) => {
  if (enabled) {
    return (
      <div>
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="text-sm text-gray-500">{subLabel}</div>
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          placeholder={placeholder}
          className="h-10 pl-4 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md"
        />
      </div>
    );
  } else {
    return null
  }
};

export const Search = ({
  type,
  label,
  name,
  id,
  subLabel,
  icon,
  placeholder,
  enabled = true
}) => {
  if (enabled) {
    return (
      <div className="relative">
        <label htmlFor="text" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="text-sm text-gray-500">{subLabel}</div>
        <i className="fa-light fa-search text-gray-500 absolute top-3 left-3"></i>
        <input
          type={type}
          name={name}
          id={id}
          className="h-10 pl-8 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md bg-gray-50"
          placeholder={placeholder}
        />
      </div>
    );
  } else {
    return null
  }
};

export const RadioButtons = ({ enabled = true, label, name, id, type }) => {
  if (enabled) {
    return (
      <div className="flex flex-row items-center">
        <input
          id={id}
          name={name}
          type={type}
          className="focus:ring-transparent h-4 w-4 border-gray-400"
        />
        <label htmlFor="push-everything" className="ml-2 block">
          {label}
        </label>
      </div>
    );
  } else {
    return null
  }
};

export const InputDollars = ({ enabled = true, type, label, name, id, subLabel, value }) => {
  if (enabled) {
    return (
      <div className="relative">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="text-sm text-gray-500">{subLabel}</div>
        <i className="fa-light fa-dollar-sign text-gray-500 absolute top-9 left-3"></i>
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          className="pl-6 mt-1 focus:ring-transparent focus:border-green-300 block w-full border-gray-400 rounded-md"
        />
      </div>
    );
  } else {
    return null
  }
};

export const InputNote = ({ enabled = true, type, name, id, placehoder }) => {
  if (enabled) {
    return (
      <div className="relative">
        <i className="fa-light fa-memo text-gray-500 absolute top-3.5 left-3"></i>
        <textarea
          type="text"
          name={name}
          id={id}
          className="pl-8 py-2.5 pr-2.5 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-green-400"
          placeholder="Type a note and press “Enter” …"
        />
      </div>
    );
  } else {
    return null
  }
};

export const InputComment = ({ enabled = true, type, name, id, placehoder, img }) => {
  if (enabled) {
    return (
      <div className="relative">
        <div className="absolute top-2.5 left-2.5">
          <Avatar img={img} enabled={getFeature("Avatar")} />
        </div>
        <textarea
          type="text"
          name={name}
          id={id}
          className="pl-14 py-2.5 pr-2.5 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-green-400"
          placeholder="Type a comment and press “Enter” …"
        />
      </div>
    );
  } else {
    return null
  }
};

export const PhoneNumber = ({ enabled = true, value, label, subLabel, error }) => {
  if (enabled) {
    return (
      <div>
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="text-sm text-gray-500">{subLabel}</div>
        <NumberFormat
          value={value}
          format="(###) ###-####"
          className={`h-10 pl-4 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md ${error ? "check-in-error" : ""
            }`}
          placeholder="(xxx) xxx-xxxx"
          id="phoneInput"
        />
      </div>
    );
  } else {
    return null
  }
};

export const AppToken = ({ enabled = true, name, id, value }) => {
  const [passwordShown, setPasswordShown, btnShown, setBtnShown] = useState(
    false
  );
  const togglePassword = () => {
    setPasswordShown(!passwordShown, !btnShown);
  };
  const [didCopy, setDidCopy] = useState(false);
  const toggleCopy = () => {
    setDidCopy(!didCopy);
  };
  if (enabled) {
    return (
      <div className="flex flex-col md:flex-row">

        <div className="flex flex-row flex-grow">
          <div>Token:</div>
          <div>
            <input
              type={passwordShown ? "text" : "password"}
              name={name}
              id={id}
              value={value}
              className="mx-3 flex flex-grow tracking-wider"
              disabled
            />
          </div>
        </div>

        <div className="mt-1 md:mt-0">
          <button
            className={didCopy ? "text-green-500" : "text-blue-500"}
            onClick={() => {
              navigator.clipboard.writeText(value);
              toggleCopy();
            }}
          >
            {didCopy ? "Copied" : "Copy"}
          </button>
          <span className="text-gray-600 mx-2">|</span>
          <button className="text-blue-500" onClick={togglePassword}>
            {passwordShown ? "Hide Token" : "Show Token"}
          </button>
        </div>
      </div>
    );
  } else {
    return null
  }
};
