// Package Dependencies
import { combineReducers } from 'redux'
// Additional Dependencies
import APIReducer from "../reducers/API/API"
import AuthorityReducer from "../reducers/Authority/Authority"
import ConfigReducer from "../reducers/Config/Config"
import PlanReducer from "../reducers/Plan/Plan"
import PlatformReducer from "../reducers/Platform/Platform"
import ProductReducer from "../reducers/Product/Product"
import ResourceReducer from "../reducers/Resource/Resource"

// Combine Reducers
const RootReducer = combineReducers({
  apiReducer: APIReducer,
  authorityReducer: AuthorityReducer,
  configReducer: ConfigReducer,
  planReducer: PlanReducer,
  platformReducer: PlatformReducer,
  productRedcuer: ProductReducer,
  resourceReducer: ResourceReducer
})
// Export
export default RootReducer
