// List Platform Request
export const LIST_PLATFORM_REQUEST = 'LIST_PLATFORM_REQUEST'

export function listPlatformRequest(request) {
  return { type: LIST_PLATFORM_REQUEST, payload: request }
}

// List Platform Success
export const LIST_PLATFORM_SUCCESS = 'LIST_PLATFORM_SUCCESS'

export function listPlatformSuccess(response) {
  return { type: LIST_PLATFORM_SUCCESS, payload: response }
}

// List Platform Failure
export const LIST_PLATFORM_FAILURE = 'LIST_PLATFORM_FAILURE'

export function listPlatformFailure(error) {
  return { type: LIST_PLATFORM_FAILURE, payload: error }
}

// Retrieve Platform Request
export const RETRIEVE_PLATFORM_REQUEST = 'RETRIEVE_PLATFORM_REQUEST'

export function retrievePlatformRequest(request) {
  return { type: RETRIEVE_PLATFORM_REQUEST, payload: request }
}

// Retrieve Platform Success
export const RETRIEVE_PLATFORM_SUCCESS = 'RETRIEVE_PLATFORM_SUCCESS'

export function retrievePlatformSuccess(response) {
  return { type: RETRIEVE_PLATFORM_SUCCESS, payload: response }
}

// Retrieve Platform Failure
export const RETRIEVE_PLATFORM_FAILURE = 'RETRIEVE_PLATFORM_FAILURE'

export function retrievePlatformFailure(error) {
  return { type: RETRIEVE_PLATFORM_FAILURE, payload: error }
}