// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_PRODUCT_REQUEST,
  retrieveProductSuccess,
  retrieveProductFailure
} from '../../actions/Product/Product'

// Function Handle Retrieve Product
function* handleRetrieveProduct(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/product/${parameter.version}/${data.uuid}`

    // Retrieve Product Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Product Success
    yield put(retrieveProductSuccess(response.data))
  } catch (error) {
    // Retrieve Product Error
    yield put(retrieveProductFailure(error))
  }
}

// Export
export const ProductSagaRetrieve = [
  takeLatest(RETRIEVE_PRODUCT_REQUEST, handleRetrieveProduct),
]