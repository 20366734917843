// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_API_REQUEST,
  retrieveAPISuccess,
  retrieveAPIFailure
} from '../../actions/API/API'

// Function Handle Retrieve API
function* handleRetrieveAPI(action) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/api/${parameter.version}/${action.payload}`
    // Retrieve API Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve API Success
    yield put(retrieveAPISuccess(response.data))
  } catch (error) {
    // Retrieve API Error
    yield put(retrieveAPIFailure(error))
  }
}

// Export
export const APISagaRetrieve = [
  takeLatest(RETRIEVE_API_REQUEST, handleRetrieveAPI),
]