import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './output.css';
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import RootReducer from "./reducers/index"
import RootSaga from "./sagas/index"

// Define Environment
const env = process.env.NODE_ENV

// Define Middleware
const sagaMiddleware = createSagaMiddleware()
const composeMiddleware = () => {
  let middleware = [applyMiddleware(sagaMiddleware)]
  if (env === "development" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }
  return compose(...middleware)
}

// Create Redux Store
const store = createStore(RootReducer, composeMiddleware())

// Run Saga Middleware
sagaMiddleware.run(RootSaga)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
