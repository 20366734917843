import React from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import { Logo } from "../../components/Logo/Logo";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const data = {progress: "66%"}

const ValidateEmail = (enabled = true) => {
  if (enabled) {
  return (
    <div className="p-2 md:p-3">
      <div className="flex items-center justify-center mb-6 mt-2">
        <div className="w-[32px] mr-2 h-[30px]">
          <Logo />
        </div>
        <div className="mr-6 text-2xl font-light">Development</div>
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
            <a href="/#/confirmation">
              <div className="flex md:items-center justify-center flex-row p-3 rounded-lg bg-gray-100 py-10">
                <div>
                  <i className="fas fa-check-circle text-xl mr-2 text-green-500"></i>
                </div>
                <div className="text-gray-700">
               Email sent. Click the validation link in your email to continue.
               </div>
             </div>
             <div className="mt-6 flex flex-row items-center">
               <div className="mr-3">
                 <Button type="tertiary" size="sm" href="/#/registration">Back</Button>
               </div>
               <div>
                 <a href="/#/">Resend Link</a>
               </div>
             </div>
           </a>
          </div>
        </MainCard>
      </div>
    </div>
  );
} else {
  return null
}
};

export default ValidateEmail;
