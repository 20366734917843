// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_API_REQUEST,
  listAPISuccess,
  listAPIFailure
} from '../../actions/API/API'

// Function Handle List API
function* handleListAPI(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/api/${parameter.version}`

    // List API Request
    const response = yield call(axios.get_request, endpoint)
    // List API Success
    yield put(listAPISuccess(response.data))
  } catch (error) {
    // List API Error
    yield put(listAPIFailure(error))
  }
}

// Export
export const APISaga = [
  takeLatest(LIST_API_REQUEST, handleListAPI),
]