import React from 'react';

const Categories = ({enabled = true, href, icon, title}) => {
  if (enabled) {
  return (
      <div>
        <a href={href}>
          <div className="border border-blue-200 bg-blue-50 hover:bg-blue-100 rounded-lg p-3 flex flex-col justify-center items-center">
            <div><i class={`${icon} text-4xl text-blue-500 mb-1`}></i></div>
            <div className="text-blue-500">{title}</div>
          </div>
        </a>
      </div>
  );
} else {
  return null
}
};

export default Categories;
