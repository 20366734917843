import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'



export const More = ({ enabled = true, items}) => {
if (enabled) {
  return (
    <div className="flex items-center my-4 md:my-0">
      {/* Profile dropdown */}
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="focus:outline-none focus:ring-none w-6">
            <div className="flex flex-row text-gray-500 text-2xl justify-center"><i className="far fa-ellipsis-v"></i></div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-8 top-0 -mt-2 w-48 text-left rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none focus:ring-none">
          {items && items.map( ( option, index ) => {
            return (
              <Menu.Item key={option.option}>
                  <Link to="/"
                    className='block py-2 px-4 text-blue-500 font-normal hover:bg-blue-50 flex flex-row items-center'
                  >
                    <div className="mr-2">{option.icon}</div><div>{option.option}</div>
                  </Link>
              </Menu.Item>
              )})}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
            }else {
              return null
            }
};
export default More;
