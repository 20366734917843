import React from 'react';

const cartItems = [
  {
    id: 1,
    title: "Operating Heavy Tools Safely 101",
    type: "Online Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    img: "https://pyvot.com/pyvot-media-server/static/8304-thumb.jpg",
    price: 19.99
  },
  {
    id: 2,
    title: "Welding 101",
    type: "Learning Path",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    img: "",
    price: 199.00,
    background: "#86BDDE",
    icon: <i className="fa-light fa-hard-hat"></i>
  },
  {
    id: 3,
    title: "Blue Plant Beginner Orientation",
    type: "In-Person Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    img: "https://pyvot.com/pyvot-media-server/static/8001-thumb.jpg",
    price: 19.99,
    location: "Alliance Safety Council",
    city: "Baton Rouge",
    state: "LA",
    startTime: "8:00 AM"
  }
]

export default cartItems;
