// List Resource RESOURCE
export const LIST_RESOURCE_REQUEST = 'LIST_RESOURCE_REQUEST'

export function listResourceRequest(request = {}) {
  return { type: LIST_RESOURCE_REQUEST, payload: request }
}

// List Resource Success
export const LIST_RESOURCE_SUCCESS = 'LIST_RESOURCE_SUCCESS'

export function listResourceSuccess(response) {
  return { type: LIST_RESOURCE_SUCCESS, payload: response }
}

// List Resource Failure
export const LIST_RESOURCE_FAILURE = 'LIST_RESOURCE_FAILURE'

export function listResourceFailure(error) {
  return { type: LIST_RESOURCE_FAILURE, payload: error }
}

// Retrieve Resource RESOURCE
export const RETRIEVE_RESOURCE_REQUEST = 'RETRIEVE_RESOURCE_REQUEST'

export function retrieveResourceRequest(request) {
  return { type: RETRIEVE_RESOURCE_REQUEST, payload: request }
}

// Retrieve Resource Success
export const RETRIEVE_RESOURCE_SUCCESS = 'RETRIEVE_RESOURCE_SUCCESS'

export function retrieveResourceSuccess(response) {
  return { type: RETRIEVE_RESOURCE_SUCCESS, payload: response }
}

// Retrieve Resource Failure
export const RETRIEVE_RESOURCE_FAILURE = 'RETRIEVE_RESOURCE_FAILURE'

export function retrieveResourceFailure(error) {
  return { type: RETRIEVE_RESOURCE_FAILURE, payload: error }
}