import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import SimpleTable from "../../components/Tables/SimpleTable";
import { RadioButtons } from "../../components/Form/Input";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import '@stoplight/elements/styles.min.css';
import Amx from "../../assets/img/amx.jpg";

const myApps = [
  {title: "My App"},
  {title: "Another Cool App"},
  {title: "SafetySoftware"}
]

const Subscribe = (enabled = true) => {
  if (enabled) {
  return (
    <div className="relative">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 md:p-4 h-full">
        <div className="mb-4">
          <PageTitle>
            <i className="fa-light fa-repeat text-orange-500 mr-2"></i>
            Subscribe and Select Plan
          </PageTitle>
        </div>
        <div className="mb-8">
          <MainCard type="main">

            <div className="flex flex-row items-center border-b border-blue-200 mb-2 pb-2">
              <div className="mr-2 bg-blue-300 h-8 w-8 rounded-full text-white flex justify-center items-center">
                <div>1</div>
              </div>
              <div className="text-lg font-medium flex-grow">Product Subscribing</div>
            </div>

            <div>Company</div>

              <div className="flex flex-row items-center border-b border-blue-200 mb-2 pb-2 mt-6">
                <div className="mr-2 bg-blue-300 h-8 w-8 rounded-full text-white flex justify-center items-center">
                  <div>2</div>
                </div>
                <div className="text-lg font-medium flex-grow">Your App</div>
              </div>

            <div className="text-gray-700 mb-2">Select the app that will use this product. Building a new app not listed here? <a href="/#/my-apps">Click here.</a></div>

            <div className="flex flex-col">
            {myApps.map((item) => (
              <div className="mb-1">
                <RadioButtons id={item.title} name="myApp" label={item.title} type="radio" />
              </div>
            ))}
            </div>

            <div className="flex flex-row items-center border-b border-blue-200 mb-2 pb-2 mt-6">
              <div className="mr-2 bg-blue-300 h-8 w-8 rounded-full text-white flex justify-center items-center">
                <div>3</div>
              </div>
              <div className="text-lg font-medium flex-grow">Choose a Plan</div>
            </div>

            <div>
              <SimpleTable />
            </div>

            <div className="flex flex-row items-center border-b border-blue-200 mb-2 pb-2 mt-6">
              <div className="mr-2 bg-blue-300 h-8 w-8 rounded-full text-white flex justify-center items-center">
                <div>4</div>
              </div>
              <div className="text-lg font-medium flex-grow">Select a Payment Method</div>
            </div>

            <div className="flex flex-row items-center mt-2">
              <div className="h-10 min-h-10 w-14 flex justify-center items-center mr-3">
                <img  src={Amx} alt="Safety Illustration"/>
              </div>
              <div>
                <div>
                  <span className="font-medium">American Express</span> ending in 4444 (<a href="/#/">change</a>)
                </div>
                <div><span className="font-medium">Billing:</span> John Doe, 1234 Main St, Jonesborough, TN 37659 (<a href="/#/">change</a>)</div>
              </div>
            </div>

            <div className="mt-10 flex flex-row">
              <div className="mr-3"><Button type="save" size="sm" href="/#/my-apps">Purchase Plan</Button></div>
              <div><Button type="tertiary" size="sm" href="/#/browse">Save</Button></div>
            </div>
          </MainCard>
        </div>
      </div>
    </div>
  );
} else {
  return null
}
};

export default Subscribe;
