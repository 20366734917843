// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  LIST_PRODUCT_REQUEST,
  listProductSuccess,
  listProductFailure
} from '../../actions/Product/Product'

// Function Handle List Product
function* handleListProduct(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0',
      sort: 'name',
      order: 'asc'
    }
    // Define Endpoint
    const endpoint = `/product/${parameter.version}?sort=${parameter.sort}&order=${parameter.order}`

    // List Product Request
    const response = yield call(axios.get_request, endpoint)
    // List Product Success
    yield put(listProductSuccess(response.data))
  } catch (error) {
    // List Product Error
    yield put(listProductFailure(error))
  }
}

// Export
export const ProductSaga = [
  takeLatest(LIST_PRODUCT_REQUEST, handleListProduct),
]
