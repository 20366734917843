import React from "react";
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import SignIn from './views/SignIn';
import Home from './views/Home';
import MyApps from './views/MyApps';
import BrowseAPIs from './views/Browse';
import APIDetails from './views/Browse/details';
import Subscribe from './views/Browse/subscribe';
import FormRegistration from './views/CreateAccount/FormRegistration';
import ValidateEmail from './views/CreateAccount/ValidateEmail';
import Confirmation from './views/CreateAccount/Confirmation';
import MyProfile from './views/MyProfile';
import { getFeature } from "./lib/util";
import { useDispatch } from 'react-redux'
import { listAPIRequest } from "./actions/API/API";
import { listAuthorityRequest } from "./actions/Authority/Authority"
import { listConfigRequest } from "./actions/Config/Config"
import { listPlanRequest } from "./actions/Plan/Plan"
import { listPlatformRequest } from "./actions/Platform/Platform"
import { listProductRequest } from "./actions/Product/Product"
import { listResourceRequest } from "./actions/Resource/Resource"

function App() {
  const dispatch = useDispatch()

  dispatch(listAPIRequest())
  dispatch(listAuthorityRequest())
  dispatch(listConfigRequest())
  dispatch(listPlanRequest())
  dispatch(listPlatformRequest())
  dispatch(listProductRequest())
  // dispatch(listResourceRequest())

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" name="Home" component={Home} />
          <Route exact path="/sign-in" name="SignIn" component={SignIn} />
          {getFeature("My Apps") && <Route exact path="/my-apps" name="MyApps" component={MyApps} />}
          {getFeature("API Products") && <Route exact path="/browse" name="BrowseAPIs" component={BrowseAPIs} />}
          {getFeature("Documentation") && <Route exact path="/documentation" name="Documentation" component={() => null} />}
          {getFeature("API Sandbox") && <Route exact path="/sandbox" name="API Sandbox" component={() => null} />}
          <Route exact path="/details/:uuid" name="APIDetails" component={APIDetails} />
          <Route exact path="/subscribe" name="Subscribe" component={Subscribe} />
          {getFeature("Avatar") && <Route exact path="/profile" name="MyProfile" component={MyProfile} />}
          <Route exact path="/registration" name="FormRegistration" component={FormRegistration} />
          <Route exact path="/validation-listening" name="ValidateEmail" component={ValidateEmail} />
          <Route exact path="/confirmation" name="Confirmation" component={Confirmation} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
