// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_PLATFORM_REQUEST,
  retrievePlatformSuccess,
  retrievePlatformFailure
} from '../../actions/Platform/Platform'

// Function Handle Retrieve Platform
function* handleRetrievePlatform(data) {
  try {
    // Define Query Parameters
    const parameter = {
      version: '1.0.0'
    }
    // Define Endpoint
    const endpoint = `/platform/${parameter.version}/${data.uuid}`

    // Retrieve Platform Request
    const response = yield call(axios.get_request, endpoint)
    // Retrieve Platform Success
    yield put(retrievePlatformSuccess(response.data))
  } catch (error) {
    // Retrieve Platform Error
    yield put(retrievePlatformFailure(error))
  }
}

// Export
export const PlatformSagaRetrieve = [
  takeLatest(RETRIEVE_PLATFORM_REQUEST, handleRetrievePlatform),
]